import { LoadableAfterPaint, LoadableLazy, LoadableBackground } from '@confluence/loadable';

export const GuestRequestToUpgradeToUser = LoadableBackground({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-GuestRequestToUpgradeToUser" */ './guest-request-upgrade/GuestRequestToUpgradeToUser'
			)
		).GuestRequestToUpgradeToUser,
});

export const GuestRequestToUpgradeDialog = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-GuestRequestToUpgradeLearnMoreModal" */ './guest-request-upgrade/GuestRequestToUpgradeLearnMoreModal'
			)
		).GuestRequestToUpgradeLearnMoreModal,
});

export const ExternalCollaboratorUserLozenge = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ExternalUserLozenge" */ './ExternalUserLozenge'))
			.ExternalUserLozenge,
});

export const ExternalCollaboratorGroupLozenge = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ExternalGroupLozenge" */ './ExternalGroupLozenge'))
			.ExternalGroupLozenge,
});

export const ExternalCollaboratorSpaceLozenge = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ExternalSpaceLozenge" */ './ExternalSpaceLozenge'))
			.ExternalSpaceLozenge,
});

export const CommentAddPromptNudgeText = LoadableAfterPaint({
	name: 'CommentAddPromptNudgeText',

	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CommentAddPromptNudgeText" */ './CommentAddPromptNudgeText'
			)
		).CommentAddPromptNudgeText,
});

export const RequestAccessForUnassignedGuest = LoadableAfterPaint({
	name: 'RequestAccessForUnassignedGuest',
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-RequestAccessForUnassignedGuest" */ './RequestAccessForUnassignedGuest'
			)
		).RequestAccessForUnassignedGuest,
});

export const GuestOnboardingModalLoadable = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GuestOnboardingModal" */ './GuestOnboardingModal'))
			.GuestOnboardingModal,
});

export { getUserPermissionFromQuery } from './Queries/getUserPermissionFromQuery';
export { SitePermissionType } from './Queries/__types__/ExternalCollaboratorQuery';
export { getExternalCollaboratorEntitlementFromQuery } from './Queries/getExternalCollaboratorEntitlementFromQuery';
export { GuestLearnMoreLink } from './guest-learn-more-link/GuestLearnMoreLink';

export { UpgradeStatus, type UpgradeStatusReason } from './guest-request-upgrade/types';
export { useGuestRequestToUpgradeStatus } from './guest-request-upgrade/useGuestRequestToUpgradeStatus';
export { useGuestRequestUpgradeStore } from './guest-request-upgrade/useGuestRequestUpgradeStore';

export { LEARN_MORE_LINK } from '../src/constants';
