import gql from 'graphql-tag';

export const ViewSpaceExternalCollaboratorsDialogQuery = gql`
	query ViewSpaceExternalCollaboratorsDialogQuery($spaceKey: String) {
		space(key: $spaceKey) {
			id
			name
			externalCollaboratorCount
			currentUser {
				isAdmin
			}
		}
	}
`;
