import gql from 'graphql-tag';

export const IsSuperAdmin = gql`
	query IsSuperAdmin {
		user(current: true) {
			id
			confluence {
				roles {
					isSuperAdmin
				}
			}
		}
	}
`;
