import gql from 'graphql-tag';

const RestrictionsUserListFragment = gql`
	fragment RestrictionsUserListFragment on PaginatedUserWithRestrictions {
		count
		nodes {
			type
			displayName
			accountId
			profilePicture {
				path
			}
			hasSpaceViewPermission
			hasSpaceEditPermission
			permissionType
			restrictingContent {
				id
				links {
					webui
					base
				}
			}
		}
		pageInfo {
			hasNextPage
			endCursor
		}
	}
`;

const groupFragment = gql`
	fragment groupFragment on PaginatedGroupList {
		count
		nodes {
			id
			name
			permissionType
		}
		pageInfo {
			hasNextPage
			endCursor
		}
	}
`;

export const ReadUserWithRestrictionsQuery = gql`
	query ReadUserWithRestrictionsQuery(
		$contentId: ID!
		$status: [String]
		$draftShareId: String
		$readUserStart: Int = 0
	) {
		content(id: $contentId, status: $status, draftShareId: $draftShareId) {
			nodes {
				id
				restrictions {
					read {
						restrictions {
							userWithRestrictions(start: $readUserStart) {
								...RestrictionsUserListFragment
							}
						}
					}
				}
			}
		}
	}

	${RestrictionsUserListFragment}
`;

export const ReadGroupsQuery = gql`
	query ReadGroupsQuery(
		$contentId: ID!
		$status: [String]
		$draftShareId: String
		$readGroupStart: Int = 0
	) {
		content(id: $contentId, status: $status, draftShareId: $draftShareId) {
			nodes {
				id
				restrictions {
					read {
						restrictions {
							group(start: $readGroupStart) {
								...groupFragment
							}
						}
					}
				}
			}
		}
	}

	${groupFragment}
`;

export const EditUserWithRestrictionsQuery = gql`
	query EditUserWithRestrictionsQuery(
		$contentId: ID!
		$status: [String]
		$draftShareId: String
		$editUserStart: Int = 0
	) {
		content(id: $contentId, status: $status, draftShareId: $draftShareId) {
			nodes {
				id
				restrictions {
					update {
						restrictions {
							userWithRestrictions(start: $editUserStart) {
								...RestrictionsUserListFragment
							}
						}
					}
				}
			}
		}
	}

	${RestrictionsUserListFragment}
`;

export const EditGroupsQuery = gql`
	query EditGroupsQuery(
		$contentId: ID!
		$status: [String]
		$draftShareId: String
		$editGroupStart: Int = 0
	) {
		content(id: $contentId, status: $status, draftShareId: $draftShareId) {
			nodes {
				id
				restrictions {
					update {
						restrictions {
							group(start: $editGroupStart) {
								...groupFragment
							}
						}
					}
				}
			}
		}
	}

	${groupFragment}
`;

export const RestrictionsDialogQuery = gql`
	query RestrictionsDialogQuery(
		$contentId: ID!
		$status: [String]
		$draftShareId: String
		$readUserStart: Int = 0
		$readGroupStart: Int = 0
		$editUserStart: Int = 0
		$editGroupStart: Int = 0
	) {
		content(id: $contentId, status: $status, draftShareId: $draftShareId) {
			nodes {
				id
				ancestors {
					id
					hasViewRestrictions
					links {
						webui
					}
				}
				hasInheritedRestrictions
				hasRestrictions
				hasViewRestrictions
				title
				type
				status
				subType
				operations {
					operation
					targetType
				}
				restrictions {
					read {
						restrictions {
							userWithRestrictions(start: $readUserStart) {
								...RestrictionsUserListFragment
							}
							group(start: $readGroupStart) {
								...groupFragment
							}
						}
					}
					update {
						restrictions {
							userWithRestrictions(start: $editUserStart) {
								...RestrictionsUserListFragment
							}
							group(start: $editGroupStart) {
								...groupFragment
							}
						}
					}
				}
				space {
					id
					containsExternalCollaborators
					externalCollaboratorCount
				}
			}
		}
		user(current: true) {
			id
			displayName
			photos {
				value
				isPrimary
			}
		}
		tenant {
			editions {
				edition
			}
		}
	}

	${RestrictionsUserListFragment}
	${groupFragment}
`;
