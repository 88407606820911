import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import type { ViewExternalCollaboratorsLinkProps } from './ViewExternalCollaboratorsLink';

const i18n = defineMessages({
	viewExternalCollaboratorsLink: {
		id: 'space-guest-list.view-external-collaborators-list.link',
		defaultMessage: 'View list',
		description: 'Text for the link which opens the list of guest users in a space',
	},
});

// TS complains if children is returned without jsx wrapper, since ReactNode =/= ReactElement
export const ViewExternalCollaboratorsLinkPlaceholder = ({
	children,
}: ViewExternalCollaboratorsLinkProps) => (
	<>{children || <FormattedMessage {...i18n.viewExternalCollaboratorsLink} />}</>
);
