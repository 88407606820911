import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import { getSuperAdminClaim } from '@confluence/super-admin-claim';

import { IsSuperAdmin } from './IsSuperAdminQuery.graphql';

export const useIsSuperAdmin = () => {
	// if the claim does not exist, we'll skip doing the actual query
	const hasSuperAdminClaim = getSuperAdminClaim();

	const { loading, error, data } = useQuery(IsSuperAdmin, {
		skip: !hasSuperAdminClaim,
	});

	return hasSuperAdminClaim
		? {
				loading,
				error,
				isSuperAdmin: Boolean(data?.user?.confluence?.roles?.isSuperAdmin),
			}
		: {
				loading: false,
				isSuperAdmin: false,
			};
};

export const AdminKeyQuery = ({ children }) => {
	const { isSuperAdmin, loading, error } = useIsSuperAdmin();

	return children({
		loading,
		error,
		isSuperAdmin,
	});
};

AdminKeyQuery.propTypes = {
	children: PropTypes.func.isRequired,
};
