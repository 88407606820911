import { LoadableBackground, LoadableAfterPaint, LoadableLazy } from '@confluence/loadable';

import { ViewExternalCollaboratorsLinkPlaceholder } from './SpaceExternalList/ViewExternalCollaboratorsLinkPlaceholder';
export { SpaceExternalListScrollable } from './SpaceExternalList/SpaceExternalListScrollable';

export const ExternalCollaboratorInSpaceNotify = LoadableBackground({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ExternalCollaboratorInSpaceNotifyExternalCollaboratorInSpaceNotify" */ './ExternalCollaboratorInSpaceNotify/ExternalCollaboratorInSpaceNotify'
			)
		).ExternalCollaboratorInSpaceNotify,
});

export const ViewExternalCollaboratorsLink = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ViewExternalCollaboratorsLink" */ './SpaceExternalList/ViewExternalCollaboratorsLink'
			)
		).ViewExternalCollaboratorsLink,
	loading: ViewExternalCollaboratorsLinkPlaceholder,
});

export const ViewSpaceExternalCollaboratorsDialogLoadable = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ViewSpaceExternalCollaboratorsDialog" */ './SpaceExternalList/ViewSpaceExternalCollaboratorsDialog'
			)
		).ViewSpaceExternalCollaboratorsDialog,
});
