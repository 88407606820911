import type { ApolloError } from 'apollo-client';
import type { FC } from 'react';
import React, { useCallback, useState, useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';
import { useQuery } from '@apollo/react-hooks';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/standard-button';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { B300, N200, N40, N700, N800, R500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { Attribution, ErrorDisplay, ErrorBoundary } from '@confluence/error-boundary';
import { useSpaceId, useSpaceKey } from '@confluence/space-utils';

import type { ExternalCollaboratorsSpaceInformationMessageProps } from '../types';
import { ViewSpaceExternalCollaboratorsDialogLoadable } from '../index';

import type {
	ViewSpaceExternalCollaboratorsDialogQuery as ViewSpaceExternalCollaboratorsDialogQueryType,
	ViewSpaceExternalCollaboratorsDialogQueryVariables,
} from './graphql/__types__/ViewSpaceExternalCollaboratorsDialogQuery';
import { ViewSpaceExternalCollaboratorsDialogQuery } from './graphql/ViewSpaceExternalCollaboratorsDialogQuery.graphql';

const i18n = defineMessages({
	spaceNavigationMessageLink: {
		id: 'space-guest-list.external-collaborator-message.navigation.guests-access-to-space',
		defaultMessage: '{guestCallToAction} in this space',
		description:
			'Distinct number of guests that have access to the currently selected space with call to action inline',
	},
	spaceMessageLink: {
		id: 'space-guest-list.external-collaborator-message.space.guests-access-to-space',
		defaultMessage:
			'{guestCallToAction} {guestCount, plural, one {has} other {have}} access to this space.',
		description:
			'Distinct number of guests that have access to the currently selected space with call to action inline',
	},
	pageMessageLink: {
		id: 'space-guest-list.external-collaborator-message.page.guests-access-to-page',
		defaultMessage: '{guestCallToAction} can access.',
		description:
			'Distinct number of guests that have access to the currently selected page with call to action inline',
	},
	pageMessageLinkWithSeparateCallToAction: {
		id: 'space-guest-list.external-collaborator-message.page.guests-access-to-page-cta',
		defaultMessage: '{guests} can access. {callToAction}',
		description:
			'Distinct number of guests that have access to the currently selected page with call to action at end',
	},
	additionalContextMessage: {
		id: 'space-guest-list.external-collaborator-message.guest-context',
		defaultMessage: 'Guests are external to your organization.',
		description: 'Additional information about guests within the currently selected space',
	},
	guestCallToActionMessage: {
		id: 'space-guest-list.external-collaborator-message.guest-count',
		defaultMessage: '{guestCount, plural, =0 {No guests} one {# guest} other {# guests}}',
		description: 'Distinct number of guests',
	},
	callToActionMessage: {
		id: 'space-guest-list.external-collaborator-message.view-guest-action',
		defaultMessage: '{guestCount, plural, one {View guest} other {View guests}}',
		description: 'Call to action to view guests',
	},
	errorMessage: {
		id: 'space-guest-list.external-collaborator-message.error',
		defaultMessage: 'An error occurred while retrieving guests.',
		description: 'Error message that occurs when failing to load guest information',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	gap: `${token('space.200', '16px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div({
	height: '24px',
	width: '24px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MessageWrapper = styled.div<{ isSubtlest: boolean }>({
	display: 'flex',
	gap: token('space.100', '8px'),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ isSubtlest }) =>
		isSubtlest ? token('color.text.subtlest', N700) : token('color.text.subtle', N800),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MessageButtonWrapper = styled.span<{ isSubtlest: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'button > span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: ({ isSubtlest }) =>
			isSubtlest ? token('color.text.subtlest', N700) : token('color.text.subtle', N800),
		textDecoration: 'underline',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'button > span:hover': {
		color: token('color.text.subtle', N200),
		textDecoration: 'underline',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-empty-styled-expression -- Ignored via go/DSP-18766
const NullButtonWrapper = styled.span<{ isSubtlest: boolean }>({});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MessageSkeleton = styled.div({
	display: 'flex',
	flex: 'auto',
	backgroundColor: token('color.background.neutral', N40),
	borderRadius: '4px',
	lineHeight: '24px;',
});

export enum ExternalCollaboratorsSpaceInformationMessageType {
	IN_SPACE = 'IN_SPACE',
	ACCESS_TO_SPACE = 'ACCESS_TO_SPACE',
	ACCESS_TO_PAGE = 'ACCESS_TO_PAGE',
	ACCESS_TO_PAGE_CTA = 'ACCESS_TO_PAGE_CTA',
}

const PRIMARY_MESSAGE = {
	[ExternalCollaboratorsSpaceInformationMessageType.IN_SPACE]: i18n.spaceNavigationMessageLink,
	[ExternalCollaboratorsSpaceInformationMessageType.ACCESS_TO_SPACE]: i18n.spaceMessageLink,
	[ExternalCollaboratorsSpaceInformationMessageType.ACCESS_TO_PAGE]: i18n.pageMessageLink,
	[ExternalCollaboratorsSpaceInformationMessageType.ACCESS_TO_PAGE_CTA]:
		i18n.pageMessageLinkWithSeparateCallToAction,
};

const getPrimaryMessage = (messageType: ExternalCollaboratorsSpaceInformationMessageType) => {
	return PRIMARY_MESSAGE[messageType] ?? i18n.spaceMessageLink;
};

const ANALYTICS_SOURCE_MODAL_DIALOG = 'viewExternalCollaboratorsModalDialog';

export const ExternalCollaboratorsSpaceInformationMessage: FC<
	ExternalCollaboratorsSpaceInformationMessageProps
> = ({
	analyticsSource,
	messageType,
	shouldShowMessageIcon,
	shouldShowAdditionalMessageContext,
	spaceKey: propSpaceKey,
	onLoadComplete,
	onLoadError,
	isSubtle,
	dontRecolorGuestCount,
}) => {
	const spaceKey = useSpaceKey(propSpaceKey);
	const spaceId = useSpaceId(propSpaceKey);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const isSubtlest = !!isSubtle;

	const { data, loading, error } = useQuery<
		ViewSpaceExternalCollaboratorsDialogQueryType,
		ViewSpaceExternalCollaboratorsDialogQueryVariables
	>(ViewSpaceExternalCollaboratorsDialogQuery, {
		variables: {
			spaceKey,
		},
	});

	const guestCount = data?.space?.externalCollaboratorCount ?? 0;

	useEffect(() => {
		if (error) {
			onLoadError?.(error as ApolloError);
		}
	}, [error, onLoadError]);

	useEffect(() => {
		if (!loading) {
			onLoadComplete?.({ guestCount });
		}
	}, [guestCount, loading, onLoadComplete]);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onButtonClick = useCallback(
		(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			e.preventDefault();

			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'viewGuestsList',
					source: analyticsSource,
					containerType: 'space',
					containerId: spaceId,
				},
			}).fire();

			setIsModalOpen(true);
		},
		[createAnalyticsEvent, spaceId, analyticsSource],
	);

	const onClose = () => {
		setIsModalOpen(false);
	};

	if (error) {
		return (
			<ErrorBoundary attribution={Attribution.EXTERNAL_COLLAB}>
				<ErrorDisplay error={error}>
					<Wrapper>
						<IconWrapper hidden={!shouldShowMessageIcon}>
							<ErrorIcon primaryColor={token('color.icon.danger', R500)} label="error" />
						</IconWrapper>
						<MessageWrapper isSubtlest={isSubtlest}>
							<FormattedMessage {...i18n.errorMessage} />
						</MessageWrapper>
					</Wrapper>
				</ErrorDisplay>
			</ErrorBoundary>
		);
	}

	if (loading) {
		return (
			<Wrapper>
				<MessageSkeleton>
					<br />
				</MessageSkeleton>
			</Wrapper>
		);
	}

	const CtaComponent = dontRecolorGuestCount ? NullButtonWrapper : MessageButtonWrapper;

	return (
		<Wrapper>
			{shouldShowMessageIcon && (
				<IconWrapper>
					<InfoIcon primaryColor={token('color.icon.information', B300)} label="info" />
				</IconWrapper>
			)}
			<MessageWrapper isSubtlest={isSubtlest}>
				<FormattedMessage
					{...getPrimaryMessage(messageType)}
					values={{
						guestCount,
						guests: (
							<FormattedMessage
								{...i18n.guestCallToActionMessage}
								values={{
									guestCount,
								}}
							/>
						),
						guestCallToAction: (
							<CtaComponent data-testid="view-external-collaborators-link" isSubtlest={isSubtlest}>
								<Button appearance="link" spacing="none" onClick={onButtonClick}>
									<FormattedMessage
										{...i18n.guestCallToActionMessage}
										values={{
											guestCount,
										}}
									/>
								</Button>
							</CtaComponent>
						),
						callToAction: (
							<Button appearance="link" spacing="none" onClick={onButtonClick}>
								<FormattedMessage
									{...i18n.callToActionMessage}
									values={{
										guestCount,
									}}
								/>
							</Button>
						),
					}}
				/>
				{shouldShowAdditionalMessageContext && (
					<FormattedMessage {...i18n.additionalContextMessage} />
				)}
			</MessageWrapper>
			<ModalTransition>
				{isModalOpen && (
					<ViewSpaceExternalCollaboratorsDialogLoadable
						spaceKey={spaceKey || ''}
						hasHeaderImage={false}
						onClose={onClose}
						onCloseComplete={() => {}}
						analyticsSource={ANALYTICS_SOURCE_MODAL_DIALOG}
						clickSource={analyticsSource}
					/>
				)}
			</ModalTransition>
		</Wrapper>
	);
};
